import styled from '@emotion/styled';

import { fonts, colours, shadows } from '../../style/variables';
import { breakpoint } from '../../style/utils';
import Divider from '../Divider';
import Container from '../Container';
import { Logo } from '../Logo';

const smallMobileBreakpoint = breakpoint({ max: 420 });

export const Header = styled.header`
  height: 500px;
  box-shadow: ${shadows.header};
  background-color: ${colours.stone};

  @media ${breakpoint({ max: 'xs' })} {
    height: 480px;
  }
`;

export const HeaderContentContainer = styled(Container)`
  height: 100%;
  padding-top: 80px;
  padding-bottom: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const BlogTitle = styled.h1`
  display: flex;
  margin: 0;
  margin-bottom: 40px;
  font-size: 80px;
  line-height: 80%;
  font-weight: normal;
  font-style: normal;
  font-family: ${fonts.brand};
  align-items: center;
  
  @media ${breakpoint({ max: 'sm' })} {
    flex-direction: column;
  }
`;

export const BlogTitleLogo = styled(Logo)`
  transition: transform 0.2s ease-in-out;
  margin: 0 14px;

  &:hover {
    transform: scale(0.95);
  }
  
  @media ${breakpoint({ max: 'sm' })} {
    flex-direction: column;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

export const BlogDescription = styled.p`
  font-size: 24px;
  margin: 0;
  text-align: center;

  @media ${breakpoint({ min: 'sm', max: 'md' })} {
    font-size: 24px;
  }
  @media ${breakpoint({ max: 'xs' })} {
    font-size: 20px;
  }
  @media ${smallMobileBreakpoint} {
    font-size: 18px;
  }
`;

export const MainContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 120px;

  @media ${breakpoint({ max: 'xs' })} {
    padding-top: 60px;
    padding-bottom: 80px;
  }
`;

export const HighlightTitle = styled.h2`
  background-color: ${colours.black};
  color: ${colours.white};
  padding: 8px 16px;
  display: inline-block;
  transform: rotate(-2deg);
  font-size: 40px;
  margin-top: 0;
  position: relative;
  z-index: 1;

  @media ${breakpoint({ max: 'md' })} {
    transform: rotate(-5deg);
    margin-bottom: -20px;
  }
  @media ${breakpoint('xxs')} {
    font-size: 32px;
  }
`;

export const HeadlineDivider = styled(Divider)`
  margin: 40px 0;
`;
