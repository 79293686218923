import React, { FunctionComponent, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { HomePageQuery } from '../../../generated/graphql-types';
import {
  Header,
  MainContainer,
  HeaderContentContainer,
  HeaderContent,
  BlogTitle,
  BlogTitleLogo,
  BlogDescription,
  HeadlineDivider,
  HighlightTitle,
} from './HomePage.style';
import Layout from '../Layout';
import ArticlePreviewList from '../ArticlePreviewList';
import ArticleHighlight from '../ArticleHighlight';
import openGraphImage from '../../assets/open-graph-image.jpg';
import SEOTags from '../SEOTags';
import Navbar from '../Navbar';

interface HomePageProps {
  data: HomePageQuery;
}

const HomePage: FunctionComponent<HomePageProps> = ({ data }) => {
  const siteMetadata = data.site?.siteMetadata;
  const highlightedArticle = data.highlightedArticle.nodes[0];
  const allArticles = data.allArticles.nodes.filter(
    (n) => n.slug !== highlightedArticle.slug
  );

  return (
    <Layout>
      <SEOTags
        title={siteMetadata?.title || 'The Planty Blog'}
        description={siteMetadata?.description || ''}
        canonical={siteMetadata?.siteUrl || ''}
        openGraph={{
          title: siteMetadata?.title || 'The Planty Blog',
          description: siteMetadata?.description || '',
          type: 'website',
          url: siteMetadata?.siteUrl || '',
          images: [
            {
              url: `${siteMetadata?.siteUrl}${openGraphImage}`,
              alt: siteMetadata?.title || '',
            },
          ],
        }}
      />
      <Navbar overlay showLogo={false} />
      <Header>
        <HeaderContentContainer>
          <HeaderContent>
            <BlogTitle>
              <span>the</span>
              <BlogTitleLogo
                kind="wordmark"
                height={68}
                title="Planty" 
              />
              <span>blog</span>
            </BlogTitle>
            <BlogDescription>{siteMetadata?.description}</BlogDescription>
          </HeaderContent>
        </HeaderContentContainer>
      </Header>
      <main>
        <MainContainer>
          {highlightedArticle && (
            <Fragment>
              <HighlightTitle>Today's Highlight</HighlightTitle>
              <ArticleHighlight article={highlightedArticle} />
              {allArticles.length > 0 && <HeadlineDivider />}
            </Fragment>
          )}
          <ArticlePreviewList articles={allArticles} />
        </MainContainer>
      </main>
    </Layout>
  );
};

export default HomePage;
